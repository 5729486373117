@font-face {
    font-family: 'Montserrat Light Alt1';
    src: url('./fonts/otf/MontserratAlt1-Light.otf') format('opentype'),
         url('./fonts/ttf/ttf/MontserratAlt1-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
 
  html, body {
     overflow-x: hidden;
     
   }
   
   * {
     box-sizing: border-box;
   }
   

